<template>
     <el-upload
             :action="uploadInterfaceIp"
             :headers="headersToken"
             :name="name" multiple
             :before-upload="handleBeforeUpload"
             :on-exceed="handleExceed"
             :on-success="handleSuccess"
             :on-remove="handleRemove"
             :on-error="handleError"
             :file-list="fileList"
             :limit="limit"
             :accept="accept"
             list-type="picture-card">
          <i class="el-icon-plus"></i>
          <div slot="tip" class="el-upload__tip">只能上传{{accept}}文件，限制最多上传数量：{{limit}}</div>
     </el-upload>
</template>

<script>
    import { baseConfig } from "@/utils/config";
    import { tokenCookies } from "@storage/cookies";
    import { MessageError, MessageWarning } from "@custom/message";
    import { createFormatUploadImages } from "@/components/Upload/index";

    export default {
        props: {
            //  文件的唯一值集合，当前系统为uuid
            uuidList: {
                type: Array,
                default: () => [],
            },
            name: {
                type: String,
                default: "file",
            },
            limit: {
                type: Number,
                default: 1,
            },
            accept: {
                type: String,
                default: ".jpg,.jpeg,.png,.gif",
            },
            // 像素大小[width，height]
            pixel: {
                type: Array,
                default: () => [],
            },
        },
        name: "upload-picture-card",
        data() {
            return {
                uploadInterfaceIp: baseConfig.baseURL + "/files/upload",
                headersToken: {
                    "X-Access-Token": tokenCookies.get(),
                },
                fileList: []
            };
        },
        created() {
            // 回显图片
            if (this.uuidList.length === 0) return;
            this.fileList = createFormatUploadImages(this.uuidList);
        },
         /*watch:{
             //2022/03/23 王江毅 监听uuidList数组传过来的数量不为0，重新渲染图片，为防止提前渲染所添加(数据还没传过来就渲染了)
              uuidList(newVal,oldVal){
                   //2022/03/23 王江毅 判断新旧数据是否一致
                   let ok= newVal.length===oldVal.length && newVal.every(function(v,i) { return v === oldVal[i]});
                   if(newVal.length!==0 && !ok) this.fileList = createFormatUploadImages(newVal);
              }
         },*/
        methods: {
            handleExceed() {
                MessageWarning(`文件总共只能上传${ this.limit }张，请先删除已上传的文件！`);
            },
            handleBeforeUpload(file) {
                return new Promise((resolve, reject) => {
                    const { name } = file;

                    // 上传文件格式
                    const acceptArray = this.accept.split(",");
                    const extensionIndex = name.lastIndexOf(".");       // 后缀序列
                    const extension = name.substring(extensionIndex);   // 文件后缀
                    this.fileName = name.substring(0, extensionIndex);  // 文件名称
                    let isFileType = acceptArray.includes(extension);
                    if (!isFileType) {
                        MessageWarning(`请上传${ this.accept }格式的文件！`);
                        reject(false);
                    }

                    resolve(isFileType);
                });
            },
            handleRemove(file, fileList) {
                // 删除
                this.$emit("on-remove", { file, fileList });
            },
            handleSuccess(response, file, fileList) {
                // 上传成功
                const { resultCode = -1 } = response;
                if (resultCode === 1000) {
                    this.imageUrl = URL.createObjectURL(file.raw);
                    const fileName = this.fileName;
                    const info = response.returnObject.info;
                    this.$emit("on-success", { fileName, file, info, fileList });
                } else {
                    this.handleError();
                }
            },
            handleError() {
                MessageError("文件上传失败", 1000, () => {
                    this.fileList = [];
                });
            },
        },
    };
</script>

<style scoped>

</style>
