<template>
    <r-e-dialog :title="dialogTitle" :visible.sync="dialogVisible" show-footer top="5vh"
                @click-submit="handleSubmitPublish" @click-cancel="handleCancel" @close="handleCancel">
        <el-form title="" ref="formPublish" label-width="110px" size="small" :model="formPublish" :rules="rules">
            <div class="flex">
                <el-form-item label="房型名称" prop="templateName">
                    <el-input v-model="formPublish.templateName" placeholder="请填写房型名称（15个字以内）"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item prop="roomSize" label="室：" label-width="80px">
                    <el-input type="number" placeholder="整数" v-model="formPublish.roomSize" :min="0"/>
                </el-form-item>
                <el-form-item prop="officeSize" label="厅：" label-width="80px">
                    <el-input type="number" placeholder="整数" v-model="formPublish.officeSize" :min="0"/>
                </el-form-item>
                <el-form-item prop="kitchenSize" label="厨：" label-width="80px">
                    <el-input type="number" placeholder="整数" v-model="formPublish.kitchenSize" :min="0"/>
                </el-form-item>
                <el-form-item prop="toiletSize" label="卫：" label-width="80px">
                    <el-input type="number" placeholder="整数" v-model="formPublish.toiletSize" :min="0"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="房屋朝向" prop="houseOrientation">
                    <el-select v-model="formPublish.houseOrientation" placeholder="房屋朝向">
                        <el-option v-for="item in orientations" :key="item.uuid" :label="item.codeName"
                                   :value="item.codeName"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="装修风格" prop="decorationStyle">
                    <el-select v-model="formPublish.decorationStyle" placeholder="装修风格">
                        <el-option v-for="item in decorationStyles" :key="item.uuid" :label="item.codeName"
                                   :value="item.codeName"/>
                    </el-select>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="付款方式" prop="paymentMethod">
                    <el-input v-model="formPublish.paymentMethod" disabled placeholder="付款方式"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="房屋标签" prop="labels">
                    <div class="tag-group">
                        <el-tag @click="handleClickLabel" type="info">全选</el-tag>
                        <el-tag @click="handleClickLabelTag(item)" v-for="item in labels" :key="item.code"
                                :type="item.type" effect="plain">{{ item.codeName }}</el-tag>
                    </div>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="房间配置" prop="configs">
                    <div class="tag-group">
                        <el-tag @click="handleClickConfig" type="info">全选</el-tag>
                        <el-tag @click="handleClickConfigTag(item)" v-for="item in configs" :key="item.code"
                                :type="item.type" effect="plain">{{ item.codeName }}</el-tag>
                    </div>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="便利设施" prop="amenities">
                    <div class="tag-group">
                        <el-tag @click="handleClickAmenities" type="info">全选</el-tag>
                        <el-tag @click="handleClickAmenitiesTag(item)" v-for="item in amenities" :key="item.code"
                                :type="item.type" effect="plain">{{ item.codeName }}</el-tag>
                    </div>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="安全设施" prop="safety">
                    <div class="tag-group">
                        <el-tag @click="handleClickSafety" type="info">全选</el-tag>
                        <el-tag @click="handleClickSafetyTag(item)" v-for="item in safety" :key="item.code"
                                :type="item.type" effect="plain">{{ item.codeName }}</el-tag>
                    </div>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="房屋描述" prop="houseDescription">
                    <el-input type="textarea" :rows="5" maxlength="500" v-model="formPublish.houseDescription"
                              show-word-limit placeholder="请在这里输入当前房型的描述（字数要求50~500）"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="房间照片" prop="samplePhotos" class="samplePhotos">
                    <upload-picture-card :uuidList="formPublish.fileList" :limit="24" @on-success="handleSuccess" @on-remove="handleRemove"/>
                </el-form-item>
            </div>
            <el-alert type="info" :closable="false">
                <i class="el-icon-warning"></i>
                （请务必上传真实照片以便我们审核您的信息）<br>
                1.第一张为封面图，封面图只能是客厅、卧室其中之一<br>
                2.建议尺寸：1366*768，最大5M；仅支持jpg、png格式<br>
                3.照片数量：最少5张，最多24张
            </el-alert>
        </el-form>
    </r-e-dialog>
</template>

<script>
    import {formPublishRules} from "@/views/rental-housing-management/publish-create/data";
    import uploadPictureCard from "@/components/Upload/upload-picture-card.vue";
    import {MessageSuccess, MessageWarning} from "@custom/message";

    export default {
        name: "layer-add-edit-publish",
        data() {
            return {
                addEdit: "add",
                dialogTitle: "新增房型",
                dialogVisible: false,
                formPublish: {
                    templateName: null,
                    roomSize: 0,
                    officeSize: 0,
                    kitchenSize: 0,
                    toiletSize: 0,
                    houseOrientation: null,
                    decorationStyle: null,
                    paymentMethod: "月付",
                    houseDescription: null,
                    labels: [],
                    configs: [],
                    samplePhotos: [],
                    amenities: [],
                    safety: [],
                },
                orientations: [],
                decorationStyles: [],
                labels: [],
                configs: [],
                amenities: [],
                safety: [],
                users: [],
                rules: formPublishRules(),
            };
        },
        components: {uploadPictureCard},
        async mounted() {
            this.orientations = await this.$store.dispatch("app/getDictionaryByCodeActions", "300000");
            this.decorationStyles = await this.$store.dispatch("app/getDictionaryByCodeActions", "400000");

            /*let users = await getUserListApi();
            this.users = users.list;*/

        },
        methods: {
            openDialog(addEdit, row) {
                this.dialogVisible = true;
            },
            handleChange(e) {
                const {mobile} = this.users.find(v => v.username === e);
                this.formPublish.houseManagePhone = mobile;
            },
            handleClickLabel() {
            },
            handleClickLabelTag(item) {
            },
            handleClickConfig() {
            },
            handleClickConfigTag(item) {
            },
            handleClickAmenities() {
            },
            handleClickAmenitiesTag(item) {
            },
            handleClickSafety() {
            },
            handleClickSafetyTag(item) {
            },
            handleSuccess({fileList}) {
                this.imgList = fileList.map(item => {
                    let {name, response} = item;
                    let resOk = response && typeof (response) !== undefined;
                    if (resOk) {
                        let {returnObject: {info: {uuid}}} = response;
                        return uuid;
                    } else return name;
                });
                this.formPublish.samplePhotos = this.imgList.join(",");
            },
            handleRemove({fileList}) {
                this.imgList = fileList.map(item => {
                    let {name, response} = item;
                    let resOk = response && typeof (response) !== undefined;
                    if (resOk) {
                        let {returnObject: {info: {uuid}}} = response;
                        return uuid;
                    } else return name;
                });
                this.formPublish.samplePhotos = this.imgList.join(",");
            },
            handleExceed(files, fileList) {
                this.$message.warning(`当前限制选择 24 个文件，本次选择了 ${files.length}
				个文件，共选择了 ${files.length + fileList.length} 个文件`);
            },
            handleSubmitPublish() {
                // 表单提交
                this.$refs["formPublish"].validate((valid) => {
                    if (valid) {
                        this.formPublish.samplePhotos = this.formPublish.samplePhotos.toString();
                        console.log(JSON.stringify(this.formPublish));
                        const request = this.addEdit === "add" ? "addPublishApi" : "editPublishApi";
                        let param = [];
                        if (this.addEdit === "edit") {
                            param.push(this.formPublish.uuid);
                        }
                        param.push(this.formPublish);
                    } else {
                        MessageWarning("请填写必填值", 1000);
                        return false;
                    }
                });
            },
            handleCancel() {
                // 取消、关闭弹窗
                this.dialogVisible = false;
                this.formPublish = {
                    paymentMethod: "月付",
                    labels: [],
                    configs: [],
                    samplePhotos: [],
                    amenities: [],
                    safety: []
                };
                this.$refs["formPublish"].resetFields();
            },

        }
    };
</script>

<style lang="scss" scoped>
    .el-select {
        width: 100%;
    }

    .flex {
        display: flex;

        /deep/ .el-form-item {
            flex: 1;
            white-space: nowrap;
        }

        /deep/ .amount {
            .el-input {
                width: 42%;
            }
        }

        /deep/ .tag-group {
            white-space: normal;

            .el-tag {
                margin: 0 VW(5px) VH(5px) 0;
                cursor: pointer;
            }
        }

        /deep/ .samplePhotos {
            white-space: normal;
        }
    }
</style>
<style>
    .v-modal {
        z-index: 2000 !important;
    }
</style>
