<template>
    <r-e-dialog :title="dialogTitle" :visible.sync="dialogVisible" show-footer @click-submit="handleSubmitPublish"
                @click-cancel="handleCancel">
        <div class="top-bar" title="">
            <el-select v-model="formSearch.publishUuid" placeholder="请选择状态" style="margin-right: 7px" size="small"
                       @change="handleChange">
                <el-option
                        v-for="item in [{ id: 1, name: '未关联' },{ id: data.publishUuid, name: '已关联' }]"
                        :key="item.id" :label="item.name" :value="item.id"/>
            </el-select>
            <el-select v-model="formSearch.uuid" placeholder="请选择小区" filterable clearable  size="small"
                       @change="handleChange" :disabled="formSearch.publishUuid!==1">
                <el-option v-for="item in communityList" :key="item.uuid" :label="item.name" :value="item.uuid"/>
            </el-select>
            <el-input v-model="formSearch.keyword" placeholder="请输入关键字搜索" style="margin: 0 7px" size="small"
                       :disabled="formSearch.publishUuid !==1 "/>
            <el-button type="primary" @click="handleChange" size="small">查询</el-button>
            <el-button @click="formSearch={page: 1, pageSize: 10000, publishUuid:1};handleChange()" size="small">
                重置
            </el-button>
            <el-alert type="success" :title="'已勾选'+data.apartmentUuids.length+'个房间'"
                      :show-icon="data.apartmentUuids.length>0" :closable="false"
                      style="margin-left: 5px; padding-top: 5px; padding-bottom: 5px;" size="small"/>
        </div>
        <el-table title="" :data="apartmentList" size="small" @selection-change="handleChange2" ref="apartmentTableRef">
            <el-table-column type="selection" width="50"/>
            <el-table-column type="index" label="序号" width="50" :index="indexMethod"/>
            <el-table-column prop="name" label="地址"/>
            <el-table-column prop="apartmentType" label="户型" width="120"/>
            <el-table-column prop="outsideArea" label="面积"
                             :formatter="(row, column, cellValue, index)=>cellValue?cellValue+'㎡':'0'" width="120"/>
            <el-table-column label="操作" width="100" v-if="formSearch.publishUuid!==1">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <span class="table-btn" @click="cancel(row)">取消</span>
                    </div>
                </template>
            </el-table-column>
            <template slot="empty">
                <el-empty/>
            </template>
        </el-table>
        <el-pagination small :total="total" :current-page="formSearch.page" :page-size="formSearch.pageSize"
                       @current-change="getApartmentList"/>
    </r-e-dialog>
</template>

<script>
    import {apartmentColumn} from "@/views/rental-housing-management/publish-create/data";
    import {MessageSuccess} from "@custom/message";

    export default {
        name: "layer-relation-publish",
        data() {
            return {
                apartmentColumn,
                dialogTitle: "关联房源",
                dialogVisible: false,
                apartmentList: [],
                total: 0,
                communityList: [],
                community: '',
                formSearch: {
                    uuid: "",
                    page: 1,
                    pageSize: 10000
                },
                data: {
                    publishUuid: "",
                    apartmentUuids: []
                },
                loadingOptions: {
                    lock: true,
                    text: "Loading...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)"
                },
            };
        },
        components: {},
        mounted() {
        },
        methods: {
            indexMethod(index) {
                return (this.formSearch.page - 1) * this.formSearch.pageSize + index + 1;
            },
            getApartmentList(e) {
                console.log(this.formSearch.publishUuid);
                this.formSearch.page = e;
                /*if (this.formSearch.publishUuid === 1) getApartmentByCommunity(this.formSearch).then(res => {
                    console.log(res);
                    this.apartmentList = res.list;
                    this.total = res.page.totalNum;
                    /!*let t=this.$refs.apartmentTableRef;
                    let arr=this.data.apartmentUuids,arr2=res.list;
                    arr2.forEach(v=>{
                      if(arr.includes(v.uuid)&&t) t.toggleRowSelection(v,true);
                    });*!/
                });
                else getApartmentByPublishUuid(this.formSearch).then(res => {
                    this.apartmentList = res.list;
                    this.total = res.page.totalNum;
                });*/
            },
            getApartmentTableData(params) {
                // console.log(params);
                // return getApartmentByCommunity(params);
                return [];
            },
            layerRelationPublishInit(row) { // 页面初始化，父页面传递数据
                // console.log(row);
                this.dialogVisible = true;
                this.formSearch = {uuid: "", page: 1, pageSize: 10, publishUuid: 1};
                this.data = {publishUuid: row.uuid, apartmentUuids: []};
                this.getApartmentList(1);
            },
            afterDataReq(e) {
                // this.$refs.apartmentTableRef.setToggleRowSelection(e,true);
            },
            handleChange(e) {
                // console.log(e);
                /*this.$refs.apartmentTableRef.pNumber = 1;
                this.$refs.apartmentTableRef.getTableData();*/
                this.getApartmentList(1);
            },
            handleChange2(e) {
                this.data.apartmentUuids = e.map(v => v.uuid);
            },
            handleSubmitPublish() {
                if (this.data.apartmentUuids.length < 1 || this.formSearch.publishUuid !== 1) {
                    this.handleCancel();
                    return;
                }
                console.log(this.data);
                /*relationPublishApi(this.data).then((res) => {
                    console.log(res);
                    MessageSuccess(res.msg);
                    this.handleCancel("update");
                });*/
            },
            handleCancel(closeType = "cancel") {
                // 取消、关闭弹窗
                this.dialogVisible = false;
                // 新增、编辑成功，需要刷新数据
                if (closeType === "update") this.$parent.$refs.publishTableRef.getTableData();
            },
            cancel(data) {
                const {uuid} = data;
                let that = this;
                this.$confirm('此操作将取消关联该房间, 是否继续?', '注意', {type: 'warning'}).then(() => {
                    const loadingOptions = that.loadingOptions;
                    const loading = that.$loading({...loadingOptions});
                    /*cancelPublish(uuid).then(res => {
                        loading.close();
                        MessageSuccess("取消关联成功");
                        that.handleChange();
                    }).catch(err => loading.close());*/
                }).catch(() => {});
            }
        }
    }
</script>

<style lang="scss" scoped>
    .layer-relation-publish {
        .top-bar {
            display: flex;
            margin-bottom: VH(10px);
        }

        .el-select {
            width: 100%;
        }

        /deep/ .el-dialog {
            width: 60%;
        }

        /deep/ .el-alert__title {
            white-space: nowrap;
        }
    }
</style>

<style>
    /*.fill .el-dialog{
      height: -webkit-fill-available;
    }*/
</style>
